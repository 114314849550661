<!-- components/DiscountBar.vue -->
<template>
  <div>
    <v-alert type="success" color="primary" class="mb-0">
      <div class="text-center">
        <div>{{ $t('pay.offerDiscount') }} </div>
        <span>{{ $t('pay.offerEnds') }} </span>
        <span v-if="!isAmp">{{ hours }} {{ $t('counters.hours') }} {{ minutes }} {{ $t('counters.minutes') }}
          {{ seconds }} {{ $t('counters.seconds') }}</span>
        <span v-else>
          <!-- AMP version of countdown -->
          <amp-date-countdown
            width="0"
            height="15"
            layout="responsive"
            :end-date="ampEndDate"
            locale="en"
            when-ended="stop"
            time-zone="UTC"
            render
          >
            <template type="amp-mustache">
              {{ hh }}{{ $t('counters.hours') }} {{ mm }}{{ $t('counters.minutes') }} {{ ss }}{{ $t('counters.seconds') }}
            </template>
          </amp-date-countdown>
        </span>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { parseCookies } from '~/utils/cookies'

export default {
  name: 'DiscountBar',
  data () {
    return {
      timer: null,
      hours: '00',
      minutes: '00',
      seconds: '00',
      endTime: null,
      ampEndDate: null,
      isAmp: false
    }
  },
  mounted () {
    this.isAmp = this.$route.path.endsWith('.amp')
    if (this.isAmp) {
      // AMP page
      this.initializeAmpCountdown()
    } else {
      // Non-AMP page
      this.initializeCountdown()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getFirstVisitTime () {
      if (process.server) {
        // On the server, access cookies from req.headers.cookie
        const cookie = require('cookie')
        const cookies = cookie.parse(this.$ssrContext.req.headers.cookie || '')
        return cookies.firstVisitTime
      } else {
        // On the client, use document.cookie
        const cookies = parseCookies(document.cookie)
        return cookies.firstVisitTime
      }
    },
    initializeCountdown () {
      let firstVisitTime = this.getFirstVisitTime()
      if (!firstVisitTime) {
        // If the cookie wasn't set, use current time
        firstVisitTime = Date.now().toString()
      }
      this.endTime = parseInt(firstVisitTime) + 1 * 60 * 60 * 1000 // Add 1 hour
      this.updateCountdown()
      this.timer = setInterval(this.updateCountdown, 1000)
    },
    updateCountdown () {
      const now = Date.now()
      const distance = this.endTime - now
      if (distance <= 0) {
        clearInterval(this.timer)
        this.hours = this.minutes = this.seconds = '00'
        // Optionally, hide the bar or show offer expired message
        return
      }
      this.hours = String(Math.floor(distance / (1000 * 60 * 60))).padStart(2, '0')
      this.minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0')
      this.seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0')
    },
    initializeAmpCountdown () {
      let firstVisitTime = this.getFirstVisitTime()
      if (!firstVisitTime) {
        // If the cookie wasn't set, use current time
        firstVisitTime = Date.now().toString()
      }
      const endTime = parseInt(firstVisitTime) + 1 * 60 * 60 * 1000
      this.ampEndDate = new Date(endTime).toISOString()
    }
  }
}
</script>

<style scoped>
/* Add any necessary styles here */
</style>
