export function parseCookies (cookieString) {
  const cookies = {}
  if (!cookieString) { return cookies }
  cookieString.split(';').forEach((cookie) => {
    const [name, ...rest] = cookie.split('=')
    const trimmedName = name.trim()
    if (!trimmedName) { return }
    const value = rest.join('=').trim()
    cookies[trimmedName] = decodeURIComponent(value)
  })
  return cookies
}
